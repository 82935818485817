import { downloadDocumentTypes } from './constants';

type downloadDocumentAction = { type: string, payload: {} | string };

// common success
export const downloadDocumentApiResponseSuccess = (actionType: string, data: any): downloadDocumentAction => ({
    type: downloadDocumentTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const downloadDocumentApiResponseError = (actionType: string, error: string): downloadDocumentAction => ({
    type: downloadDocumentTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getDocumentByStorageId = (storageId:string): downloadDocumentAction => ({
    type: downloadDocumentTypes.GET_DOCUMENT,
    payload: {storageId},
});
