import { EmailTemplateTypes } from './constants';

const INIT_STATE = { loading: false };

type customEmailTemplateAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};

type State = { emailTemplate?: {} | null, loading?: boolean, error?: string };

const CustomEmailTemplate = (state: State = INIT_STATE, action: customEmailTemplateAction): any => {
    switch (action.type) {
        case EmailTemplateTypes.API_RESPONSE_SUCCESS:
            return {
                ...state,
                emailTemplate: action.payload.data,
                loading: false,
            };

        case EmailTemplateTypes.API_RESPONSE_ERROR:
            return {
                ...state,
                error: action.payload.error,
                emailTemplate: false,
                loading: false,
            };

        case EmailTemplateTypes.CREATE_EDITCUSTOMEMAILTEMPLATE:
            return {
                ...state,
                loading: true,
                emailTemplate: false,
            };

        case EmailTemplateTypes.RESET:
            return {
                ...state,
                loading: false,
                error: false,
                emailTemplate: null,
            };
        default:
            return { ...state };
    }
};

export default CustomEmailTemplate;
