import { dashboardDataTypes } from './constants';

const INIT_STATE = { loading: false };

type dashboardAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};

type State = { dashboardData?: {} | null, loading?: boolean, error?: string };

const DashboarData = (state: State = INIT_STATE, action: dashboardAction): any => {
     switch (action.type) {
        case dashboardDataTypes.API_RESPONSE_SUCCESS:
            return {
                ...state,
                dashboardData: action.payload.data,
                loading: false,
            };

        case dashboardDataTypes.API_RESPONSE_ERROR:
            return {
                ...state,
                error: action.payload.error,
                dashboardData: false,
                loading: false,
            };       
            case dashboardDataTypes.GET_DASHBOARD:
                return { ...state, loading: true, dashboardData: false };
    
            case dashboardDataTypes.RESET:
                return {
                    ...state,
                    loading: false,
                    error: false,
                    dashboardData: false,
                };
        default:
            return { ...state };
    }
};

export default DashboarData;