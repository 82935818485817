import { masterDataTypes } from './constants';

const INIT_STATE = { loading: false };

type masterdataAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};

type State = { masterdataAction?: {} | null, loading?: boolean, error?: string, isDisplay?: Boolean };

const masterdata = (state: State = INIT_STATE, action: masterdataAction): any => {
    switch (action.type) {
        case masterDataTypes.API_RESPONSE_SUCCESS:
            return {
                ...state,
                masterdataAction: action.payload.data,
                loading: false,
            };

        case masterDataTypes.API_RESPONSE_ERROR:
            return {
                ...state,
                error: action.payload.error,
                masterdataAction: false,
                loading: false,
            };

        case masterDataTypes.GET_ROLES:
            return { ...state, loading: true, masterdataAction: false, isDisplay: action.payload };

        case masterDataTypes.RESET:
            return {
                ...state,
                loading: false,
                error: false,
                masterdataAction: false,
            };

        default:
            return { ...state };
    }
};

export default masterdata;
