import Auth from './auth/reducers';
import Layout from './layout/reducers';
import { combineReducers } from 'redux';
import CreateUser from './user/reducers';
import GetRoles from './masterData/reducers';
import CreateTempUser from './user/reducers';
import GetUserDetails from './EditUser/reducers';
import DashboardInvitation from './dashboard/reducers';
import ListOfDynamicMenus from './dynamicMenus/reducers';
import DashboardData from './dashboardData/reducers';
import GetDownloadDocument from './downloadDocument/reducers';
import GetEmailTemplateNames from './SendInvitation/reducers';
import CreateCustomeEditEmailTemplate from './SaveEmailTemplate/reducers';
import SaveEmailTemplate from './SaveEmailTemplate/reducers';

export default (combineReducers({
    Auth,
    Layout,
    GetRoles,
    CreateUser,
    CreateTempUser,
    GetUserDetails,
    DashboardData,
    ListOfDynamicMenus,
    GetDownloadDocument,
    DashboardInvitation,
    GetEmailTemplateNames,
    CreateCustomeEditEmailTemplate,
    SaveEmailTemplate,
}): any);
