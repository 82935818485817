import { editUserTypes } from './constants';

type editUserAction = { type: string, payload: {} | string };

export const editUserApiResponseSuccess = (actionType: string, data: any): editUserAction => ({
    type: editUserTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const editUserApiResponseError = (actionType: string, error: string): editUserAction => ({
    type: editUserTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const GetUserDetails = (userId: int): editUserAction => ({
    type: editUserTypes.GET_USERDETAILS,
    payload: { userId },
});
