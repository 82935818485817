import { Navigate } from 'react-router-dom';
import * as authHelper from '../helpers/authHelper';
import { CaseDocumentsPageURL, Roles } from '../constants/apiConstants';

const Root = () => {
    const loggedInUser = authHelper.getLoggedInUser();

    const getRootUrl = () => {
        let url = 'dashboard';
        return url;
    };

    const url = getRootUrl();

    if (authHelper.IsNullOrUndefined(loggedInUser)) {
        if (
            loggedInUser.roleId === Roles.Debtor || //Debtor role
            loggedInUser.roleId === Roles.DebtorAttorney //Debtor Attorney role
        )
            return <Navigate to={{ pathname: CaseDocumentsPageURL }} />;
    }

    return <Navigate to={`/${url}`} />;
};

export default Root;
