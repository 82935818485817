import { dashboardTypes } from './constants';

const INIT_STATE = { loading: false };

type dashboardAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};

type State = { dashboardAction?: {} | null, loading?: boolean, error?: string };

const DashboardList = (state: State = INIT_STATE, action: dashboardAction): any => {
    switch (action.type) {
        case dashboardTypes.API_RESPONSE_SUCCESS:
            return {
                ...state,
                dashboardData: action.payload.data,
                loading: false,
            };

        case dashboardTypes.API_RESPONSE_ERROR:
            return {
                ...state,
                error: action.payload.error,
                dashboardData: false,
                loading: false,
            };

        case dashboardTypes.GET_DASHBOARDLIST:
            return { ...state, loading: true, dashboardData: false };

        case dashboardTypes.RESET:
            return {
                ...state,
                loading: false,
                error: false,
                dashboardData: false,
            };
        default:
            return { ...state };
    }
};

export default DashboardList;
