import { downloadDocumentTypes } from './constants';

const INIT_STATE = { loading: false };

type downloadDocumentAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};

type State = { downloadDocumentData?: {} | null, loading?: boolean, error?: string };

const DownloadDocument = (state: State = INIT_STATE, action: downloadDocumentAction): any => {
     switch (action.type) {
        case downloadDocumentTypes.API_RESPONSE_SUCCESS:
            return {
                ...state,
                downloadDocumentData: action.payload.data,
                loading: false,
            };

        case downloadDocumentTypes.API_RESPONSE_ERROR:
            return {
                ...state,
                error: action.payload.error,
                downloadDocumentData: false,
                loading: false,
            };

        case downloadDocumentTypes.GET_DOCUMENT:
                return { ...state, loading: true, downloadDocumentData: false };

        case downloadDocumentTypes.RESET:
            return {
                ...state,
                loading: false,
                error: false,
                downloadDocumentData: false,
            };
        default:
            return { ...state };
    }
};

export default DownloadDocument;