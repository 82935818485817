import { masterDataTypes } from './constants';
import { GetRoles } from '../../helpers/api/auth';
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { masterDataApiResponseSuccess, masterDataActionApiResponseError } from './actions';

function* GetRolesSaga({ payload }: { payload: { isDisplay: boolean } }) {
    try {
        const response = yield call(GetRoles, payload);
        yield put(masterDataApiResponseSuccess(masterDataTypes.API_RESPONSE_SUCCESS, response.data));
    } catch (error) {
        yield put(masterDataActionApiResponseError(masterDataTypes.API_RESPONSE_ERROR, error));
    }
}

export function* watchGetRolesDisplay(): any {
    yield takeEvery(masterDataTypes.GET_ROLES, GetRolesSaga);
}

function* dynamicmasterDataSaga(): any {
    yield all([fork(watchGetRolesDisplay)]);
}

export default dynamicmasterDataSaga;
