import { dynamicMenuTypes } from './constants';

const INIT_STATE = { loading: false };

type dynamicMenuAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};

type State = { dynamicMenuData?: {} | null, loading?: boolean, error?: string, roleId?: int, userId?: int };

const DynamicMenu = (state: State = INIT_STATE, action: dynamicMenuAction): any => {
    switch (action.type) {
        case dynamicMenuTypes.API_RESPONSE_SUCCESS:
            return {
                ...state,
                dynamicMenuData: action.payload.data,
                dynamicMenu: true,
                loading: false,
            };

        case dynamicMenuTypes.API_RESPONSE_ERROR:
            return {
                ...state,
                error: action.payload.error,
                dynamicMenuData: false,
                loading: false,
            };

        case dynamicMenuTypes.GET_LIST_OF_MENUS:
            return {
                ...state,
                loading: true,
                dynamicMenuData: false,
                roleId: action.payload.roleId,
                roleId: action.payload.userId,
                isAccessCaseDocuments: action.payload.isAccessCaseDocuments,
            };

        case dynamicMenuTypes.RESET:
            return {
                ...state,
                loading: false,
                error: false,
                dynamicMenuData: false,
            };
        default:
            return { ...state };
    }
};

export default DynamicMenu;
