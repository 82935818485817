import { SendInvitationDataTypes } from './constants';

type SendInvitationDataAction = { type: string, payload: {} | string };

export const SendInvitationDataApiResponseSuccess = (actionType: string, data: any): SendInvitationDataAction => ({
    type: SendInvitationDataTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const SendInvitationDataActionApiResponseError = (
    actionType: string,
    error: string
): SendInvitationDataAction => ({
    type: SendInvitationDataTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

// export const GetListOfEmailTemplateNames = (): SendInvitationDataAction => ({
//     type: SendInvitationDataTypes.GET_LISTOF_EMAILTEMPLATENAMES,
//     payload: {},
// });

export const SendInvitations = (SendInvitationDataAction: any): SendInvitationDataAction => ({
    type: SendInvitationDataTypes.SEND_INVITATIONSLIST,
    payload: { SendInvitationDataAction },
});
