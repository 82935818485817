import { EmailTemplateTypes } from './constants';

type emailTemplateAction = { type: string, payload: {} | string };

export const emailTemplateAPIResponseSuccess = (actionType: string, data: any): emailTemplateAction => ({
    type: EmailTemplateTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const emailTemplateAPIResponseError = (actionType: string, error: string): emailTemplateAction => ({
    type: EmailTemplateTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const EditCustomEmailTemplate = (emailTemplate: any): emailTemplateAction => ({
    type: EmailTemplateTypes.CREATE_EDITCUSTOMEMAILTEMPLATE,
    payload: { emailTemplate },
});

export const EmailTemplateReset = (): emailTemplateAction => ({
    type: EmailTemplateTypes.RESET,
    payload: {},
});

