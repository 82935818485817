import { SendInvitationDataTypes } from './constants';
import { SendInvitations } from '../../helpers/api/auth';
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SendInvitationDataApiResponseSuccess, SendInvitationDataActionApiResponseError } from './actions';

function* SendInvitationsSaga({ payload }: { payload: { data: any } }) {
    try {
        const response = yield call(SendInvitations, payload);
        yield put(SendInvitationDataApiResponseSuccess(SendInvitationDataTypes.API_RESPONSE_SUCCESS, response.data));
    } catch (error) {
        yield put(SendInvitationDataActionApiResponseError(SendInvitationDataTypes.API_RESPONSE_ERROR, error));
    }
}

export function* watchSendInvitationsDisplay(): any {
    yield takeEvery(SendInvitationDataTypes.SEND_INVITATIONSLIST, SendInvitationsSaga);
}

function* dynamicSendInvitationsSaga(): any {
    yield all([fork(watchSendInvitationsDisplay)]);
}

export default dynamicSendInvitationsSaga;
