import { dashboardDataTypes } from './constants';
import { GetDashboardData } from '../../helpers/api/auth';
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { dashboardDataApiResponseSuccess, dashboardDataApiResponseError } from './actions';

function* getDashboardDataSaga({ payload }: { payload: { categoryCount: int, userId: int } }) {
    try {
        const response = yield call(GetDashboardData, payload);
        yield put(dashboardDataApiResponseSuccess(dashboardDataTypes.API_RESPONSE_SUCCESS, response.data));
    } catch (error) {
        yield put(dashboardDataApiResponseError(dashboardDataTypes.API_RESPONSE_ERROR, error));
    }
}

export function* watchgetDashboardDataDisplay(): any {
    yield takeEvery(dashboardDataTypes.GET_DASHBOARD, getDashboardDataSaga);
}

function* dynamicDashboardDataSaga(): any {
    yield all([fork(watchgetDashboardDataDisplay)]);
}

export default dynamicDashboardDataSaga;
