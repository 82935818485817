import { dashboardTypes } from './constants';
import { getUserComponentList } from '../../helpers/api/auth';
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { dashboardApiResponseSuccess, dashboardApiResponseError } from './actions';

function* getDashboardSaga({ payload }: { payload: { roleId: int } }) {
    try {
        const response = yield call(getUserComponentList, payload);
        yield put(dashboardApiResponseSuccess(dashboardTypes.API_RESPONSE_SUCCESS, response.data));
    } catch (error) {
        yield put(dashboardApiResponseError(dashboardTypes.API_RESPONSE_ERROR, error));
    }
}

export function* watchgetDashboardDisplay(): any {
    yield takeEvery(dashboardTypes.GET_DASHBOARDLIST, getDashboardSaga);
}

function* dynamicDashboardSaga(): any {
    yield all([fork(watchgetDashboardDisplay)]);
}

export default dynamicDashboardSaga;
