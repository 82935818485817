import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { ComponentType, SessionExpiredText } from '../constants/apiConstants';

const d = new Date();
let year = d.getFullYear();

export const AUTH_SESSION_KEY = 'DDP_User_Session';
export const PAGE_SESSION_KEY = 'DDP_MENU_Session';
export const DASHBOARD_SESSION_KEY = 'DDP_Dashboard_Session';
export const EmailRegex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
export const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*()-_+={}|:;"'<,>.?])[A-Za-z\d~!@#$%^&*()-_+={}|:;"'<,>.?]{8,}$/;
export const PasswordComplexity =
    'The password must be a minimum of 8 characters and include at least 1 special character, 1 uppercase letter, 1 lowercase letter, and 1 number';

export const setAuthorization = (token) => {
    if (token) axios.defaults.headers.common['Authorization'] = 'JWT ' + token;
    else delete axios.defaults.headers.common['Authorization'];
};

export const getUserFromSession = () => {
    const user = localStorage.getItem(AUTH_SESSION_KEY);
    return user ? (typeof user === 'object' ? user : JSON.parse(user)) : null;
};

export const getDashboardFromSession = () => {
    const dashboard = localStorage.getItem(DASHBOARD_SESSION_KEY);
    return dashboard ? (typeof dashboard === 'object' ? dashboard : JSON.parse(dashboard)) : null;
};

export const getPageFromSession = () => {
    const page = localStorage.getItem(PAGE_SESSION_KEY);
    return page ? (typeof page === 'object' ? page : JSON.parse(page)) : null;
};

export const isUserAuthenticated = () => {
    const user = getLoggedInUser();

    if (!user || (user && !user.token)) return false;

    const decoded = jwtDecode(user.token);
    const currentTime = Date.now() / 1000;

    if (decoded.exp < currentTime) {
        clearSession();
        console.log('access token expired');
        localStorage.clear();
        alert(SessionExpiredText);
        return false;
    } else return true;
};

export const setLoggedInUser = (user) => {
    if (user) localStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(user));
    else localStorage.removeItem(AUTH_SESSION_KEY);
};

export const setUserComponentData = (componentData) => {
    let dashbaordlist = [];
    let pagelist = [];

    if (componentData) {
        dashbaordlist = componentData.filter((item) => {
            return item.componentTypeId === ComponentType.Dashboard;
        });

        pagelist = componentData.filter((item) => {
            return item.componentTypeId === ComponentType.Page;
        });

        localStorage.setItem(PAGE_SESSION_KEY, JSON.stringify(pagelist));
        localStorage.setItem(DASHBOARD_SESSION_KEY, JSON.stringify(dashbaordlist));
    } else {
        localStorage.removeItem(PAGE_SESSION_KEY);
        localStorage.removeItem(DASHBOARD_SESSION_KEY);
    }
};

export const getLoggedInUser = () => {
    return getUserFromSession(); // Returns the logged in user
};

export const setUserInSession = (modifiedUser) => {
    let userInfo = localStorage.getItem(AUTH_SESSION_KEY);

    if (userInfo) {
        const { token, user } = JSON.parse(userInfo);
        this.setLoggedInUser({ token, ...user, ...modifiedUser });
    }
};

export const IsNullOrUndefined = (value) => {
    if (value === null || value === undefined || value === '' || value === '') return false;
    else return true;
};

export const HandleEmailValidation = (emailAddress) => {
    if (!IsNullOrUndefined(emailAddress) || EmailRegex.test(emailAddress) === false) return false;
    else return true;
};

export const HandlePasswordValidation = (password) => {
    if (!IsNullOrUndefined(password) || PasswordRegex.test(password) === false) return false;
    else return true;
};

export const clearSession = () => {
    setLoggedInUser(null);
    setAuthorization(null);
    setUserComponentData(null);
};

export const dateComparator = (date1, date2) => {
    const date1Number = monthToComparableNumber(date1.substring(0, 10));
    const date2Number = monthToComparableNumber(date2.substring(0, 10));

    if (date1Number === null && date2Number === null) {
        return 0;
    }

    if (date1Number === null) {
        return -1;
    }

    if (date2Number === null) {
        return 1;
    }

    return date1Number - date2Number;
};

// eg 29/08/2004 gets converted to 20040829
export const monthToComparableNumber = (date) => {
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    const yearNumber = Number.parseInt(date.substring(6, 10));
    const monthNumber = Number.parseInt(date.substring(0, 2));
    const dayNumber = Number.parseInt(date.substring(3, 5));

    return yearNumber * 10000 + monthNumber * 100 + dayNumber;
};

export var filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {       
        var dateAsString = cellValue.substring(0, 10);

        if (dateAsString === null) return -1;

        var cellDate = new Date(
            Number(dateAsString.substring(6, 10)),
            Number(dateAsString.substring(0, 2)) - 1,
            Number(dateAsString.substring(3, 5))
        );

        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
        }

        if (cellDate < filterLocalDateAtMidnight) {
            return -1;
        }

        if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        }

        return 0;
    },
    
    minValidYear: 2000,
    maxValidYear: year,
};


export const maskEmail = (email = '') => {
    var [name, domain] = email.split('@');

    if(name.length > 5){
        var maskedEmail = name.split('');
        
        for(var i = 0; i < maskedEmail.length; i++){
            if (i > 1 && i != maskedEmail.length - 1){
                maskedEmail[i] = "*";
            }
        }

        return maskedEmail.join('') + '@' + domain;
    } else return email;
 };