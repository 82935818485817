import { dashboardTypes } from './constants';

type dashboardAction = { type: string, payload: {} | string };

export const dashboardApiResponseSuccess = (actionType: string, data: any): dashboardAction => ({
    type: dashboardTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const dashboardApiResponseError = (actionType: string, error: string): dashboardAction => ({
    type: dashboardTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getUserComponentList = (roleId: int, isAccessCaseDocuments: Boolean): dashboardAction => ({
    type: dashboardTypes.GET_DASHBOARDLIST,
    payload: { roleId, isAccessCaseDocuments },
});
