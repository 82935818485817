import { masterDataTypes } from './constants';

type masterDataAction = { type: string, payload: {} | string };

export const masterDataApiResponseSuccess = (actionType: string, data: any): masterDataAction => ({
    type: masterDataTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const masterDataActionApiResponseError = (actionType: string, error: string): masterDataAction => ({
    type: masterDataTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const GetRoles = (isDisplay: Boolean): masterDataAction => ({
    type: masterDataTypes.GET_ROLES,
    payload: { isDisplay },
});
