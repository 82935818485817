import axios from 'axios';
import config from '../../config';
import * as authHelper from '../../helpers/authHelper';

axios.defaults.baseURL = config.API_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';

// intercepting to capture errors
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        let message;

        if (error && error.response && error.response.status === 404) {
            authHelper.clearSession();
            window.location.href = '/PageNotFound';
        } else if (error && error.response && (error.response.status === 403 || error.response.status === 401)) {
            authHelper.clearSession();
            window.location.href = '/UnAuthorizedAccess';
        } else {
            switch (error.response.status) {
                case 401:
                    message = 'Invalid credentials';
                    break;

                case 403:
                    message = 'Access Forbidden';
                    break;

                case 404:
                    message = 'Sorry! the data you are looking for could not be found';
                    break;

                default: {
                    message =
                        error.response && error.response.data ? error.response.data['message'] : error.message || error;
                }
            }
            return Promise.reject(message);
        }
    }
);

class APICore {
    // Fetches data from given url
    get = (url, params) => {
        let response;
        let token = '';
        let user = authHelper.getUserFromSession();

        if (user) token = user.token;

        axios.defaults.headers.common = {
            Authorization: `Bearer ${token}`,
        };

        if (params) {
            var queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
            response = axios.get(`${url}?${queryString}`, params);
        } else {
            response = axios.get(`${url}`, params);
        }

        return response;
    };

    getFile = (url, params) => {
        let response;
        if (params) {
            var queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
            response = axios.get(`${url}?${queryString}`, { responseType: 'blob' });
        } else {
            response = axios.get(`${url}`, { responseType: 'blob' });
        }
        return response;
    };

    getMultiple = (urls, params) => {
        const reqs = [];
        let queryString = '';
        if (params) {
            queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
        }

        for (const url of urls) {
            reqs.push(axios.get(`${url}?${queryString}`));
        }
        return axios.all(reqs);
    };

    //  post given data to url
    create = (url, data) => {
        let token = '';
        let user = authHelper.getUserFromSession();

        if (user) token = user.token;

        axios.defaults.headers.common = {
            Authorization: `Bearer ${token}`,
        };

        return axios.post(url, data);
    };

    //  Updates patch data
    updatePatch = (url, data) => {
        return axios.patch(url, data);
    };

    // Updates data
    update = (url, data) => {
        return axios.put(url, data);
    };

    //  Deletes data
    delete = (url) => {
        return axios.delete(url);
    };

    //  post given data to url with file
    createWithFile = (url, data) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const config = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post(url, formData, config);
    };

    //  post given data to url with file
    updateWithFile = (url, data) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const config = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.patch(url, formData, config);
    };
}

let user = authHelper.getUserFromSession(); // Check if token available in session

if (user) {
    const { token } = user;

    if (token) {
        authHelper.setAuthorization(token);
    }
}

export { APICore };
