import { dashboardDataTypes } from './constants';

type dashboardAction = { type: string, payload: {} | string };

export const dashboardDataApiResponseSuccess = (actionType: string, data: any): dashboardAction => ({
    type: dashboardDataTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const dashboardDataApiResponseError = (actionType: string, error: string): dashboardAction => ({
    type: dashboardDataTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getDashboardDataDisplay = (categoryCount: string, userId: int): dashboardAction => ({
    type: dashboardDataTypes.GET_DASHBOARD,
    payload: { categoryCount, userId },
});
