import { EmailTemplateTypes } from './constants';
import { CreateCustomeEditEmailTemplate } from '../../helpers/api/auth';
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { emailTemplateAPIResponseSuccess, emailTemplateAPIResponseError } from './actions';

function* EditCustomEmailTemplateSaga({ payload }: { payload: { emailTemplate: any } }) {
    try {
        const response = yield call(CreateCustomeEditEmailTemplate, payload.emailTemplate);
        yield put(emailTemplateAPIResponseSuccess(EmailTemplateTypes.API_RESPONSE_SUCCESS, response.data));
    } catch (error) {
        yield put(emailTemplateAPIResponseError(EmailTemplateTypes.API_RESPONSE_ERROR, error));
    }
}

export function* watchEditCustomEmailTemplateDisplay(): any {
    yield takeEvery(EmailTemplateTypes.CREATE_EDITCUSTOMEMAILTEMPLATE, EditCustomEmailTemplateSaga);
}

function* dynamicEditCustomEmailTemplateSaga(): any {
    yield all([fork(watchEditCustomEmailTemplateDisplay)]);
}

export default dynamicEditCustomEmailTemplateSaga;
