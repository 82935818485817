import { useEffect } from 'react';
import * as authHelper from './helpers/authHelper';
import { LoginURL, PageActionEvents, SessionExpireHours, SessionExpiredText } from './constants/apiConstants';

const IdleSessionLogout = ({ children }) => {
    let timer;
    let sessionExpireInMilliseconds = SessionExpireHours * 3600000;

    // when component mounts, it adds an event listeners to the window
    // each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to logout user after api configure secs of inactivity resets.
    // However, if none of the event is triggered within api configure secs, that is app is inactive, the app automatically logs out.
    useEffect(() => {
        Object.values(PageActionEvents).forEach((item) => {
            window.addEventListener(item, () => {
                resetTimer();
                handleLogoutTimer();
            });
        });
    }, []);

    const handleLogoutTimer = () => {
        // this function sets the timer that logs out the user after api configure secs
        timer = setTimeout(() => {
            resetTimer(); // clears any pending timer.

            Object.values(PageActionEvents).forEach((item) => {
                // Listener clean up. Removes the existing event listener from the window
                window.removeEventListener(item, resetTimer);
            });

            logoutAction(); // logs out user
        }, sessionExpireInMilliseconds);
    }; // 10000ms = 10secs, 1 hr = 3,600,000 ms You can change the time.

    const resetTimer = () => {
        // this resets the timer if it exists.
        if (timer) clearTimeout(timer);
    };

    const logoutAction = () => {
        // logs out user by clearing out auth token in localStorage and redirecting url to /signin page.
        let pageName = window.location.pathname;

        if (pageName !== '/account/login') {
            alert(SessionExpiredText);

            localStorage.clear();
            authHelper.clearSession();
            window.location.href = LoginURL;
        }
    };

    return children;
};

export default IdleSessionLogout;
