import { AuthActionTypes } from './constants';
import * as authHelper from '../../helpers/authHelper';
import { login, getUserComponentList } from '../../helpers/api/auth';
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { authApiResponseSuccess, authApiResponseError } from './actions';
import eventBus from '../../layouts/EventBus';

function* loginSaga({ payload: { username, password, baseURL, invitationAuthKey } }) {
    try {
        const response = yield call(login, { username, password, baseURL, invitationAuthKey });
        const user = response.data;
        user.userKeySuccess = false;
        authHelper.setLoggedInUser(user); //PrivateRoute.js set the authorization before the route can be accessed or not
        authHelper.setAuthorization(user['token']); // token setting from API to React

        const dashboardResponse = yield call(getUserComponentList, { user });
        authHelper.setUserComponentData(dashboardResponse.data);
        yield put(authApiResponseSuccess(AuthActionTypes.API_RESPONSE_SUCCESS, user));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.API_RESPONSE_ERROR, error));
        authHelper.clearSession();
    }
}

function* logout() {
    try {
        authHelper.clearSession();
        eventBus.remove('newUserName');
        yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
    }
}

export function* watchLoginUser(): any {
    yield takeEvery(AuthActionTypes.LOGIN_USER, loginSaga);
}

export function* watchLogout(): any {
    yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

function* authSaga(): any {
    yield all([fork(watchLoginUser), fork(watchLogout)]);
}

export default authSaga;
