import { userTypes } from './constants';

type userAction = { type: string, payload: {} | string };

export const userApiResponseSuccess = (actionType: string, data: any): userAction => ({
    type: userTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const userApiResponseError = (actionType: string, error: string): userAction => ({
    type: userTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const CreateUser = (user: any): userAction => ({
    type: userTypes.CREATE_USER,
    payload: { user },
});

export const CreateTempUser = (user: any): userAction => ({
    type: userTypes.CREATE_TEMP_USER,
    payload: { user },
});
