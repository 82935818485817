import { all, fork, put, takeEvery, call } from 'redux-saga/effects';

import { getDocumentByStorageId } from '../../helpers/api/auth';

import { downloadDocumentApiResponseSuccess, downloadDocumentApiResponseError } from './actions';
import { downloadDocumentTypes } from './constants';

// const api = new APICore();

function* getDocumentSaga({payload} :{ payload:{ storageId:string} }) {
  
    try {
        const response = yield call(getDocumentByStorageId, payload.storageId);
        yield put(downloadDocumentApiResponseSuccess(downloadDocumentTypes.API_RESPONSE_SUCCESS, response.data));
    } catch (error) {
        yield put(downloadDocumentApiResponseError(downloadDocumentTypes.API_RESPONSE_ERROR, error));
    }
}

export function* watchgetDocumentDisplay(): any {
   
    yield takeEvery(downloadDocumentTypes.GET_DOCUMENT, getDocumentSaga);
}

function* dynamicDOwnloadDocumentSaga(): any {
   
    yield all([fork(watchgetDocumentDisplay)]);
}

export default dynamicDOwnloadDocumentSaga;
