import { editUserTypes } from './constants';

const INIT_STATE = { loading: false, userData: [], error: false };

type editUserAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};

type State = { userData?: {} | null, loading?: boolean, error?: string };

const GetUserDetails = (state: State = INIT_STATE, action: editUserAction): any => {
    switch (action.type) {
        case editUserTypes.API_RESPONSE_SUCCESS:
            return {
                ...state,
                editUserAction: action.payload.data,
                loading: false,
            };

        case editUserTypes.API_RESPONSE_ERROR:
            return {
                ...state,
                error: action.payload.error,
                userData: false,
                loading: false,
            };

        // case editUserTypes.CREATE_USER:
        //     return { ...state, loading: true, userData: false };

        case editUserTypes.GET_USERDETAILS:
            return {
                ...state,
                loading: false,
                editUserAction: action.payload.data,
            };

        case editUserTypes.RESET:
            return {
                ...state,
                loading: false,
                error: false,
                userData: false,
            };
        default:
            return { ...state };
    }
};

export default GetUserDetails;
