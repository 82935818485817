import config from '../config';

export const FailedStatus = 'Failed';

export const Submitter = 'Submitter';

export const AboutEpiq = 'AboutEpiq';

export const ContactUs = 'ContactUs';

export const Recipient = 'Recipient';

export const OnlyStingRegex = /\d+/g;

export const specialCharPattern = /[^a-zA-Z0-9\s]/;

export const NoSpaceStringRegex = /\s/g;

export const SuperAdmin = 'Super Admin';

export const LoginURL = '/account/login';

export const UserNameRegex = /[^\w\s]/gi;

export const API_BASE_URL = config.api_url;

export const DashboardURL = '/dashboard';

export const AccountLocked = 'AccountLocked';

export const RegisterURL = '/account/Register';

export const CaseNumberRegex = /^(\d){2}-(\d){5}/;

export const SessionExpireHours = config.loginExpireHours;

const loginHelpURL = window.location.origin + '/Account/LoginHelp';

export const CaseDocumentsPageURL = '/apps/ecommerce/CaseDocuments';

export const FooterContent = '2018 - 2023 © Delivery Portal - epiqglobal.com';

export const SessionExpiredText = 'Your session has expired. Please login again.';

export const accountLogout =
    'Your account has been deleted or deactivated. You have been logged out, please contact support.';

export const PageActionEvents = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

export const accountLockedText = (
    <>
        You have reached the maximum number of login attempts and your account has been locked. Please access{' '}
        <a href={loginHelpURL}>Login Help</a> to reset your password
    </>
);

export const GetRequestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
};

export const RoundButton = {
    marginTop: '29px',
    borderradius: '25px',
};

export const BorderStyle = {
    padding: '5px',
    borderradius: '25px',
    border: '1px solid #313a46',
};

export const Roles = {
    SuperAdmin: -1,
    AdminRecipient: -2,
    Recipient: -3,
    Submitter: -4,
    SubmitterDelegates: -5,
    RecipientDelegates: -6,
    TempRole: -7,
    Debtor: -8,
    DebtorAttorney: -9,
    Creditor: -10,
};

export const ComponentType = {
    Dashboard: 1,
    Page: 2,
};

export const InvitationStatus = {
    Pending: 1,
    Approved: 2,
    Rejected: 3,
    Expired: 4,
};

export const EmailTemplateType = {
    Invitation: 1,
    DebtorStatement: 2,
    AttorneyStatement: 3,
    TrusteeDocument: 4,
};

export const DocumentType = {
    PortalDocument: 1,
    DebtorDocument: 2,
    DebtorAttorneyDocument: 3,
};

export const ComponentNames = {
    CaseList: 'CaseList',
    UserList: 'UserList',
    UserMigration: 'UserMigration',
    CaseDocuments: 'CaseDocuments',
    RecipientsList: 'RecipientsList',
    SubmittersList: 'SubmittersList',
    TrackingHistory: 'TrackingHistory',
    DocumentHistory: 'DocumentHistory',
    EmailHistoryList: 'EmailHistoryList',
    EmailTemplateList: 'EmailTemplateList',
    CaseDocumentUpload: 'CaseDocumentUpload',
    PendingInvitations: 'PendingInvitations',
    TrusteeCategoryList: 'TrusteeCategoryList',
    DocumentCategoryList: 'DocumentCategoryList',
    NoticingCategoryList: 'NoticingCategoryList',
    ApprovedDelegatesList: 'ApprovedDelegatesList',
    LegacyDocumentHistory: 'LegacyDocumentHistory',
    DelegatesRequestingAuthorization: 'DelegatesRequestingAuthorization',
    NoticingDocumentsHistory: 'NoticingDocumentsHistory',
};

export const GridExportColumns = {
    RecipientsList: [{ Name: 'name' }, { 'Email Address': 'emailAddress' }],

    DelegateRequesting: [{ Name: 'sendUserName' }, { 'Email Address': 'sendUserEmail' }],

    UserList: [{ Name: 'displayName' }, { 'Email Address': 'emailAddress' }, { Role: 'roleName' }],

    ApprovedDelegates: [
        { Role: 'roleName' },
        { 'Receiver User Name': 'receiverUserName' },
        { 'Receiver Email': 'receiverEmail' },
        { Name: 'sendUserName' },
        { Email: 'sendUserEmail' },
    ],

    CaseDocument: [
        { 'File Name': 'fileName' },
        { 'Case Number': 'caseNumber' },
        { 'Document Type': 'documentTypeName' },
        { 'Sent By': 'sentBy' },
        { 'Sent To': 'sentTo' },
        { 'Submitted On': 'submittedOnFormatted' },
        { 'Delivered On': 'deliveredOnFormatted' },
    ],

    TrackingHistory: [
        { 'Case Number': 'caseNumber' },
        { 'Document Category': 'documentCategory' },
        { 'Uploaded On': 'uploadedOnFormatted' },
        { 'Uploaded By': 'uploadedBy' },
        { 'Sent To': 'sentTo' },
        { 'Email Address': 'sentToEmailAddress' },
        { Viewed: 'viewed' },
        { 'First Viewed Date': 'firstViewedDateFormatted' },
    ],

    DocumentHistory: [
        { 'File Name': 'fileName' },
        { Category: 'category' },
        { 'Case Number': 'caseNumber' },
        { 'Sent By': 'sentBy' },
        { 'Sent To': 'sentTo' },
        { 'Submitted On': 'submittedOnFormatted' },
        { 'Delivered On': 'deliveredOnFormatted' },
        { Status: 'status' },
    ],

    DocumentCategoryList: [
        { 'Category Code': 'categoryCode' },
        { 'Category Description': 'categoryDescription' },
        { 'Recipient Name': 'name' },
        { Status: 'documentStatus' },
    ],

    NoticingCategoryList: [
        { 'Category Code': 'categoryCode' },
        { 'Category Description': 'categoryDescription' },
        { 'Recipient Name': 'recipientName' },
        { Status: 'documentStatus' },
    ],

    TrusteeCategoryList: [
        { 'Category Code': 'categoryCode' },
        { 'Category Description': 'categoryDescription' },
        { 'Recipient Name': 'name' },
        { Status: 'documentStatus' },
    ],

    EmailHistoryList: [
        { 'Email Type': 'invitationTypeName' },
        { Name: 'receiverName' },
        { Email: 'receiverEmail' },
        { 'Sent On': 'emailSentDateFormatted' },
        { 'Sent By': 'sentBy' },
        { Status: 'status' },
    ],

    EmailTemplateList: [
        { 'Template Name': 'templateName' },
        { 'Modified On': 'modifiedWhenFormatted' },
        { 'Modified By': 'modifiedBy' },
        { 'Template Type': 'templateType' },
    ],

    LegacyDocumentHistory: [
        { 'File Name': 'fileName' },
        { Category: 'category' },
        { 'Case Number': 'caseNumber' },
        { 'Sent By': 'sentBy' },
        { 'Sent To': 'sentTo' },
        { 'Date Sent': 'submittedOnFormatted' },
        { Status: 'status' },
    ],

    SubmittersList: [
        { 'Submitter Name': 'receiverName' },
        { 'Submitter Email': 'receiverEmail' },
        { 'Recipient Name': 'sendUserName' },
        { 'Recipient Email': 'sendUserEmail' },
        { Status: 'status' },
    ],

    UserMigration: [
        { Recipient: 'name' },
        { 'ECF Control Number': 'epiqECFControlNumber' },
        { 'Users Migrated': 'isUsersMigrated' },
        { 'Email Sent': 'isEmailSent' },
        { 'Document History': 'isDocumentHistory' },
        { 'Migration Completed': 'isMigrationCompleted' },
    ],

    CaseList: [
        { 'Client Code': 'trusteeNumber' },
        { 'Case Number': 'caseNumber' },
        { 'Last Name': 'debtorLastName' },
        { Chapter: 'chapterCode' },
        { District: 'districtCode' },
    ],

    NoticingDocumentsHistory: [
        { 'File Name': 'fileName' },
        { Category: 'category' },
        { 'Case Number': 'caseNumber' },
        { 'Sent By': 'sentBy' },
        { 'Sent To': 'sentTo' },
        { 'Application Id': 'applicationId' },
        { 'Submitted On': 'submittedOnFormatted' },
        { 'Delivered On': 'deliveredOnFormatted' },
        { Status: 'status' },
    ],
};
