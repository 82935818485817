import React, { useEffect } from 'react';
import './assets/scss/Saas.scss';
import Routes from './routes/Routes';
import { logoutAllTabs } from './pages/account/Logout';

type AppProps = {};

const App = (props: AppProps): React$Element<any> => {

    useEffect(() => {
        logoutAllTabs()
       }, [])

    return <Routes></Routes>;
};

export default App;
