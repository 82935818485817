import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import { all } from 'redux-saga/effects';
import createUserSaga from './user/saga';
import getUserSaga from './EditUser/saga';
import masterDataSaga from './masterData/saga';
import dynamicMenuSaga from './dynamicMenus/saga';
import SendInvitations from './SendInvitation/saga';
import dynamicDashboardSaga from './dashboard/saga';
import createTempUserSaga from './user/tempUsersaga';
import downloaddocumentSaga from './downloadDocument/saga';
import SaveemailTemplateSaga from './SaveEmailTemplate/saga';
import dashboardDataSaga from './dashboardData/saga';

export default function* rootSaga(): any {
    yield all([
        authSaga(),
        layoutSaga(),
        getUserSaga(),
        masterDataSaga(),
        createUserSaga(),
        dynamicMenuSaga(),
        SendInvitations(),
        createTempUserSaga(),
        downloaddocumentSaga(),
        dynamicDashboardSaga(),
        SaveemailTemplateSaga(),
        dashboardDataSaga(),
    ]);
}
