import { userTypes } from './constants';
import { CreateUser } from '../../helpers/api/auth';
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { userApiResponseSuccess, userApiResponseError } from './actions';

function* createUserSaga({ payload }: { payload: { user: any } }) {
    try {
        const response = yield call(CreateUser, payload.user);
        yield put(userApiResponseSuccess(userTypes.API_RESPONSE_SUCCESS, response.data));
    } catch (error) {
        yield put(userApiResponseError(userTypes.API_RESPONSE_ERROR, error));
    }
}

export function* watchCreateUserDisplay(): any {
    yield takeEvery(userTypes.CREATE_USER, createUserSaga);
}

function* dynamicCreateUserSaga(): any {
    yield all([fork(watchCreateUserDisplay)]);
}

export default dynamicCreateUserSaga;
