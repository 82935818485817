import { Link, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import AccountLayout from './AccountLayout';
import { useTranslation } from 'react-i18next';
import { logoutUser } from '../../redux/actions';
import { LoginURL, accountLogout } from '../../constants/apiConstants';
import logoutIcon from '../../assets/images/logout-icon.svg';
import { BroadcastChannel } from 'broadcast-channel';

const logoutChannel = new BroadcastChannel('logout');

const onLogInClick = () => {
    window.location.href = LoginURL;
};

const BottomLink = () => {
    const { t } = useTranslation();
    return (
        <Row className="mt-3">
            <Col className="text-center">
                <p className="text-muted">
                    {t('Back to ')}
                    <Link to="" className="text-muted ms-1" onClick={onLogInClick}>
                        <b>{t('Log In')}</b>
                    </Link>
                </p>
            </Col>
        </Row>
    );
};

const Logout = (): React$Element<any> | React$Element<React$FragmentType> => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const type = searchParams.get('type');
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (type !== 'autoLogout') {
            logoutChannel.postMessage('Logout');

            if (type === 'accountDeActiveLogout') setShowModal(true);

            dispatch(logoutUser());
        }
    }, [dispatch]);

    return (
        <>
            <AccountLayout bottomLinks={<BottomLink />}>
                <div className="text-center w-75 m-auto">
                    <h4 className="text-dark-50 text-center mt-0 fw-bold">{t('See You Again !')}</h4>
                    <p className="text-muted">{t('You are now successfully logged out.')}</p>

                    <div className="logout-icon m-auto">
                        <img src={logoutIcon} alt="" />
                    </div>
                </div>
            </AccountLayout>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Account deactivated or deleted</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="successMessage">{accountLogout}?</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" onClick={() => setShowModal(false)}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Logout;

export const logoutAllTabs = () => {
    if (logoutChannel !== undefined) {
        logoutChannel.onmessage = () => {
            logoutChannel.close();
            window.location.href = '\\account\\logout?type=autoLogout';
        };
    }
};
