import * as authHelper from '../helpers/authHelper';
import { LoginURL, Roles } from '../constants/apiConstants';
import { Navigate, useLocation } from 'react-router-dom';

type PrivateRouteProps = {
    component: React.ComponentType,
    roles?: string,
};

const PrivateRoute = ({ component: RouteComponent, roles, ...rest }: PrivateRouteProps) => {
    let location = useLocation();
    const loggedInUser = authHelper.getLoggedInUser();

    if (authHelper.isUserAuthenticated() === false || loggedInUser.userKeySuccess === false)
        return <Navigate to={LoginURL} state={{ from: location }} replace />; //If not logged in, so redirect to login page with the return url

    // check if route is restricted by role - If the role is admin then only we will get the menus
    if (roles && roles.indexOf(loggedInUser.role) === -1) {
        return <Navigate to={{ pathname: '/' }} />; // role authorised so redirect to dashboard page
    }

    if (
        (loggedInUser != null && loggedInUser.roleId === Roles.Debtor) || //Debtor role
        (loggedInUser != null && loggedInUser.roleId === Roles.DebtorAttorney) //Debtor Attorney role
    ) {
        if (location.pathname.includes('CaseDocuments')) return <Navigate to={{ pathname: '/CaseDocuments' }} />;
    }

    return <RouteComponent />;
};

export default PrivateRoute;
