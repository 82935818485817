import { userTypes } from './constants';

const INIT_STATE = { loading: false };

type userAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};

type State = { userData?: {} | null, loading?: boolean, error?: string };

const User = (state: State = INIT_STATE, action: userAction): any => {
    switch (action.type) {
        case userTypes.API_RESPONSE_SUCCESS:
            return {
                ...state,
                userData: action.payload.data,
                loading: false,
            };

        case userTypes.API_RESPONSE_ERROR:
            return {
                ...state,
                error: action.payload.error,
                userData: false,
                loading: false,
            };

        case userTypes.CREATE_USER:
            return { ...state, loading: true, userData: false };

        case userTypes.CREATE_TEMP_USER:
            return { ...state, loading: true, userData: false };

        case userTypes.RESET:
            return {
                ...state,
                loading: false,
                error: false,
                userData: false,
            };
        default:
            return { ...state };
    }
};

export default User;
