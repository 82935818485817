import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ImageB from '../../Images/login-background.jpg';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FooterContent } from '../../constants/apiConstants';
import Logo from '../../assets/images/epiq_logo_delivery_portal.png';

type AccountLayoutProps = {
    bottomLinks?: React$Element<any>,
    children?: any,
};

const AccountLayout = ({ bottomLinks, children }: AccountLayoutProps): React$Element<any> => {
    const { t } = useTranslation();

    useEffect(() => {
        if (document.body) document.body.classList.add('authentication-bg');

        return () => {
            if (document.body) document.body.classList.remove('authentication-bg');
        };
    }, []);

    return (
        <>
            <div
                className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5"
                style={{
                    backgroundImage: `url(${ImageB})`,
                    backgroundPosition: '50%',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                }}>
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5} xxl={4} style={{ marginTop: '-80px' }}>
                            <Card>
                                {/* logo */}
                                <Card.Header
                                    className="text-center"
                                    style={{ backgroundColor: '#eef2f7', border: '0' }}>
                                    <Link to="/">
                                        <span>
                                            <img src={Logo} alt="" height="50" />
                                        </span>
                                    </Link>
                                </Card.Header>
                                <Card.Body>{children}</Card.Body>
                            </Card>

                            {/* bottom links */}
                            {bottomLinks}
                        </Col>
                    </Row>
                </Container>
            </div>
            <footer className="footer footer-alt">{t(FooterContent)}</footer>
        </>
    );
};

export default AccountLayout;
