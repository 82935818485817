import { editUserTypes } from './constants';
import { GetUserDetails } from '../../helpers/api/auth';
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { editUserApiResponseSuccess, editUserApiResponseError } from './actions';

function* GetUserDetailsSaga({ payload }: { userId: int }) {
    try {
        const response = yield call(GetUserDetails, payload.userId);
        yield put(editUserApiResponseSuccess(editUserTypes.API_RESPONSE_SUCCESS, response.data));
    } catch (error) {
        yield put(editUserApiResponseError(editUserTypes.API_RESPONSE_ERROR, error));
    }
}

export function* watchGetUserDetailsDisplay(): any {
    yield takeEvery(editUserTypes.GET_USERDETAILS, GetUserDetailsSaga);
}

function* dynamicGetUserDetailsSaga(): any {
    yield all([fork(watchGetUserDetailsDisplay)]);
}

export default dynamicGetUserDetailsSaga;
