import { SendInvitationDataTypes } from './constants';

const INIT_STATE = { loading: false };

type SendInvitationDataAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};

type State = { SendInvitationDataAction?: {} | null, loading?: boolean, error?: string };

const SendInvitationData = (state: State = INIT_STATE, action: SendInvitationDataAction): any => {
    switch (action.type) {
        case SendInvitationDataTypes.API_RESPONSE_SUCCESS:
            return {
                ...state,
                SendInvitationDataAction: action.payload.data,
                loading: false,
            };

        case SendInvitationDataTypes.API_RESPONSE_ERROR:
            return {
                ...state,
                error: action.payload.error,
                SendInvitationDataAction: false,
                loading: false,
            };

        case SendInvitationDataTypes.GET_ROLES:
            return { ...state, loading: true, SendInvitationDataAction: false };

        case SendInvitationDataTypes.RESET:
            return {
                ...state,
                loading: false,
                error: false,
                SendInvitationDataAction: false,
            };
        
        case SendInvitationDataTypes.SEND_INVITATIONSLIST:
                return {
                    ...state,
                    loading: true,
                    error: false,
                    SendInvitationDataAction: action.payload,
                };

        default:
            return { ...state };
    }
};

export default SendInvitationData;
