import { userTypes } from './constants';
import { CreateTempUser } from '../../helpers/api/auth';
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { userApiResponseSuccess, userApiResponseError } from './actions';

function* createTempUserSaga({ payload }: { payload: { user: any } }) {
    try {
        const response = yield call(CreateTempUser, payload.user);
        yield put(userApiResponseSuccess(userTypes.API_RESPONSE_SUCCESS, response.data));
    } catch (error) {
        yield put(userApiResponseError(userTypes.API_RESPONSE_ERROR, error));
    }
}

export function* watchCreateTempUserDisplay(): any {
    yield takeEvery(userTypes.CREATE_TEMP_USER, createTempUserSaga);
}

function* dynamicCreateTempUserSaga(): any {
    yield all([fork(watchCreateTempUserDisplay)]);
}

export default dynamicCreateTempUserSaga;
